@import url("https://fonts.googleapis.com/css?family=Raleway:400,300,600,650,700|Poppins:100,200,300,400,500,600,700,800,900|Overpass:100,200,300,400,600,700,800,900");

/* global */
html, body {
    font-family: 'Raleway', 'Overpass', 'Poppins', sans-serif;
}
a {
    text-decoration: none;
    border-bottom: 1px dotted;
    padding-bottom: 4px;
}
.container {
    max-width: 1120px;
    padding: 0 1.25rem;
}
main {
    min-height: 800px;
}
footer {
    border-top: 1px solid rgba(255, 255, 255, 0);
    font-size: .9rem;
    padding-top: 15px;
    margin: 100px 0 40px 0;
    text-align: center;
    font-weight: 300;
}
footer .badge {
    padding: 6px 10px 5px 10px;
    font-weight: 300;
    margin: 3px;
}
.fw-medium {
    font-weight: 500;
}
.fw-semibold {
    font-weight: 600;
}
.paging {
    display: inline-block;
    position: relative;
    font-size: 16px;
    top: 2px;
}
@media (min-width: 768px) {
    footer {
        margin-bottom: 60px;
    }
}

/* card */
.card {
    border-radius: 10px;
    margin-bottom: 20px;
}
@media (min-width: 768px) {
    .card-body {
        padding: 30px;
    }
}

/* spinner */
.spinner {
    width: 3rem; 
    height: 3rem;
}